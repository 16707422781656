import React from "react";
import * as styles from "./index.module.scss";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";

export default function ElnDemo() {
	return (
		<BackgroundFadeContainer type="blueFade" className={styles.demoContainer}>
            <div className={styles.video}>
                <iframe src="https://player.vimeo.com/video/754836406?h=271c6cebd9" 
                    style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}
                    allow="autoplay; fullscreen; picture-in-picture" 
                />
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
		</BackgroundFadeContainer>
	);
}